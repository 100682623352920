import debounce from "debounce";
import { useState, useRef } from "react";
import { findSlot } from "../../utils.js";

import ErrorMessage from "../generics/Error.jsx";
import GreenLabel from "../generics/GreenLabel.jsx";
function TextInput({
	onChange = (e) =>
		console.warn(`[Text Input]: No event handler bound for input.`),
	className = "",
	id = false,
	name = "",
	value = "",
	type = "text",
	error = false,
	placeholder = "Enter a value",
	children,
	useDebounce = false,
	min = null,
	max = null,
	onBlur = null,
	onFocus = null,
	onInput = null,
	...others
}) {
	if (!id) throw new Error("[Text Input]: ID is not set");

	const LabelSlot = (props) => findSlot(children, props, GreenLabel);
	const ErrorSlot = (props) => findSlot(children, props, ErrorMessage);

	const [currentValue, setCurrentValue] = useState(value);
	const debouncedChange = useRef(debounce((value) => onChange(value), 200));
	// 	.current;

	return (
		<div className={`${className} pb-6 relative cus_input_outer`}>
			<LabelSlot id={id} error={error} />
			<input
				id={id}
				aria-labelledby={LabelSlot() ? `${id}_label` : null}
				onBlur={onBlur}
				onFocus={onFocus}
				onInput={onInput}
				onChange={(e) => {
					const { value } = e.target;
					// setCurrentValue(value);
					if (!useDebounce) {
						if (type !== "number") {
							onChange(value);
						} else if (min || max) {
							const numberValue = parseInt(value);
							if (min && numberValue < min) return;
							if (max && numberValue > max) return;
							onChange(value);
						}
					} else {
						setCurrentValue(value);
						debouncedChange.current(value);
					}
				}}
				className="w-full md:max-w-xl rounded-lg border-2 border-solid  px-3 py-2 border-gray-400 cus_input"
				type={type}
				min={min}
				max={max}
				name={name || id}
				value={!useDebounce ? value : currentValue}
				placeholder={placeholder}
				{...others}
			/>
			{error && <ErrorSlot className="absolute bottom-0" />}
		</div>
	);
}

TextInput.Label = GreenLabel;
TextInput.ErrorMessage = ErrorMessage;
export default TextInput;
