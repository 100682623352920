import { useEffect } from "react";
import {
  pageLinkObject,
  paramsOnLoad,
  removeUserAuthToken,
  storeUserAuthToken,
} from "../utils";
import axios from "axios";
import { useGlobalStateContext } from "../contexts/GlobalContext";
import { useHistory } from "react-router-dom";
import ROUTES from "../routes";
import Loader from "../components/generics/Loader";
import { useState } from "react";
import { AUTH_COOKIE_EXP, returnEnvDomain } from "../const";

const RedirectAbandonBooking = () => {
  const uvi = paramsOnLoad.get("uvi");
  const { setGlobalState } = useGlobalStateContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (uvi) {
      axios
        .post(
          `${returnEnvDomain()}/api/abandonbooking/verify-notification-link`,
          {
            uvi,
          }
        )
        .then((res) => {
          setIsLoading(false);
          const {
            abandon_booking_details: response,
            user_info,
            token,
          } = res.data;

          if (response.userType == "new") {
            removeUserAuthToken();
            storeUserAuthToken(token, "", true);
          } else {
            storeUserAuthToken(
              token,
              AUTH_COOKIE_EXP,
              response.userMessageType
            );
          }

          const state = {
            userIsAuthed: response.userType == "new" ? false : true,
            newUser: response.userType == "new" ? true : false,
            appointmentWasBooked: false,
            appointmentType: response.serviceDetail?.serviceName,
            appointmentTypeId: response.serviceDetail?.serviceId,
            appointmentRange: response.appointmentRange,
            appointmentTypes: response.appointmentTypes,
            careTypes: response.careTypes,
            searchAddress: response.searchAddress,
            selectedAppointmentSlot: response.isSlotBooked
              ? {}
              : response.selectedAppointmentSlot,
            selectedLocation: response.isSlotBooked
              ? {}
              : response.selectedLocation,
            serviceOption: response.serviceOption,
            appointmentReason: response.serviceDetail.moreNote,
            appointmentReasonOtherText: response.serviceDetail.otherNote,
            patients: response.patients,
            selectedPatients: response.selectedPatients,
            ...response.client,
            loginWith: response.userMessageType,
            userType: response.userType,
            authToken: response.userType == "new" ? null : token,
          };
          setGlobalState(state);
          if (response.isSlotBooked) {
            history.push(ROUTES.selectAppointment);
          } else {
            if (response?.pageName) {
              history.push(pageLinkObject[response.pageName]);
            } else {
              history.push(ROUTES.type);
            }
          }
          //   setGlobalState({ ...state });
        })
        .catch((error) => {
          setIsLoading(false);
          console.log("Err-->>", error);
          history.push(ROUTES.type);
        });
    }
  }, [uvi]);

  return <>{isLoading && <Loader />}</>;
};

export default RedirectAbandonBooking;
