export const errorObject = (errors, route = null) => {
  const errorObj = { error: Object.keys(errors).length > 0, errors };
  if (route) {
    errorObj.path = route;
  }
  return errorObj;
};

//US based phone number validation/Expression.
export const isPhoneNumberFormat = (phoneNumber) => {
  const phoneNumberFormat =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  const inputTrim = phoneNumber?.trim();
  if (inputTrim?.match(phoneNumberFormat)) return true;

  return false;
};

export const extractOnlyNumber = (phoneNumber) => {
  var formattedPhoneNumber = phoneNumber.match(/\d/g);
  const phoneNumberLength = formattedPhoneNumber.length;
  if (phoneNumberLength === 10) {
    return formattedPhoneNumber.join("");
  } else {
    const formattedPhoneNumbermorethanTen = formattedPhoneNumber
      .slice(1)
      .slice(-10)
      .join("");
    return formattedPhoneNumbermorethanTen;
  }
};

export const isEmailValid = (email) => {
  return /\S+@\S+\.\S+/.test(email);

  /*
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
          return true;
  
      return false;
      */
};

export const valueToMask = (value, mask = "(___) ___-____") => {
  let counter = 0;
  let str = "";
  for (let i = 0; i < mask?.length; i++) {
    if (mask[i] === "_" && counter < value?.length) {
      str += value[counter];
      counter++;
    } else {
      str += mask[i];
    }
  }

  return str;
};
