import { useState, useEffect, useCallback } from "react";
import useSWR from "swr";
import useGoogleMaps from "./useGoogleMaps";
import { SESSION_STORAGE_KEYS } from "../const";
import { getDistanceFromLatLon } from '../const';
import sortBy from "lodash/sortBy";
import { getGeocode, getLatLng, getDistanceMatrix } from "use-places-autocomplete";

/**
 * Uses the same style of logic as the marketing side of the site;
 * the returned locations are the top 3 results.
 */
const convertToLatLngObj = (lat, lng) => new window.google.maps.LatLng(lat, lng);

export default function useTodaysLocations(
	locationQuery = "",
	locationType = null
) {

	const { data, loading, error } = useSWR(
		"https://heartandpaw.com/wp-json/hap/locations",
		(endpoint) => {
			if (sessionStorage.getItem(SESSION_STORAGE_KEYS.LOCATIONS)) {
				return Promise.resolve(
					JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEYS.LOCATIONS))
				);
			}
			return fetch(endpoint)
				.then((res) => res.json())
				.then((locations) => {
					const filterLocation = locations?.filter((loc) => loc.meta.location_meta_booking_tool === true);
					sessionStorage.setItem(
						SESSION_STORAGE_KEYS.LOCATIONS,
						JSON.stringify(filterLocation)
					);

					return filterLocation;

				});
		},
		{
			revalidateOnFocus: false,
		}
	);


	// state
	const [topLocations, setTopLocations] = useState([]);
	const [topLocationsStatus, setTopLocationsStatus] = useState("not_ran");
	const [userLocation, setUserLocation] = useState(null);
	const [hasError, setHasError] = useState(false);

	const lookupLocations = useCallback(
		async ({ locationQuery, locationType, vetLocations }) => {
			setTopLocationsStatus("loading");

			console.log(54, locationQuery);

			try{
			var geocodedData = userLocation
				? userLocation
				: await geocodeUser(locationQuery)
			}
			catch(e){

				setTopLocations([]);
				setTopLocationsStatus("error");

return;
			}
			const { lat, lng } = geocodedData.data;

			console.log("geocoded data", geocodedData);
			// if we don't have a lat / lng combo, return empty
			if (!lat || !lng) {
				setTopLocations([]);
				setTopLocationsStatus("error");
				return;
			}

			setUserLocation(geocodedData);
			let locations = [];

			console.log("vetLocations", vetLocations);

			locations = (vetLocations?.length ? [...vetLocations].map((l) => ({ ...l })) : [])
				.map((location) => {
					const { locations_meta_latitude, locations_meta_longitude } = location.meta;
					location.distance = getDistanceFromLatLon(locations_meta_latitude, locations_meta_longitude, lat, lng);
					return location;
				})
				.filter((location) => {
					const services = location.meta.locations_meta_services.map((s) =>
						s.toLowerCase()
					);

					return location.distance < 50 && services.indexOf(locationType) >= 0;
				});
			locations = sortBy(locations, "distance");

			// if we've filtered everything out, return nothing
			if (!locations.length) {
				setTopLocations([]);
				setTopLocationsStatus("idle");
				return;
			}

			const origins = [new window.google.maps.LatLng(lat, lng)];
			const destinations = locations.map((loc) => {
				const { locations_meta_latitude, locations_meta_longitude } = loc.meta;
				return convertToLatLngObj(
					locations_meta_latitude,
					locations_meta_longitude
				);
			});

			try {
				const distanceMatrixResults = await distanceMatrixLocations(
					origins,
					destinations
				);

				distanceMatrixResults.rows[0].elements.forEach((item, idx) => {
					if (item.status === "OK") {
						locations[idx].distance = item.distance.value;
						locations[idx].distanceText = item.distance.text;
					} else {
						locations[idx].distance = Infinity;
					}
				});

				locations = sortBy(
					locations.filter((l) => {
						const miles = 0.000621371 * l.distance;
						return miles < 50;
					}),
					"distance"
				);
			} catch (err) {
				console.error(`[topLocations Hook]: distance failed - `, err);
				setTopLocations([]);
				setTopLocationsStatus("error");
			}

			setTopLocations(locations);
			setTopLocationsStatus("idle");
		},
		[]
	);


	async function geocodeUser(query) {
		return new Promise((res, rej) => {

			getGeocode({ address: query }).then((results) => {
				const { lat, lng } = getLatLng(results[0]);
				res({
					error: false,
					data: {
						lat: lat,
						lng: lng
					}
				});
			}).catch((error) => {
				rej({ error: true, errorMessage: error, data: null })
					
				
			});
		});
	}

	async function distanceMatrixLocations(origins, destinations) {
		return new Promise((res, rej) => {

			let distanceMatrix;
			distanceMatrix = new window.google.maps.DistanceMatrixService();
			distanceMatrix.getDistanceMatrix(
				{
					origins,
					destinations,
					travelMode: "DRIVING",
					unitSystem: window.google.maps.UnitSystem.IMPERIAL,
				},
				(results, status) => {
					console.log("[DISTANCE]: Before resolve/reject", results, status);
					if (status === "OK") {
						res(results);

					} else {
						rej(status);
					}
				}
			);
		});
	}

	useEffect(() => {
		if (data && locationQuery.length && locationType) {
			lookupLocations({
				locationQuery,
				locationType,
				vetLocations: data,
			});
		}
	}, [data, locationQuery, locationType]);

	return [
		loading ||
		topLocationsStatus === "not_ran",
		topLocations,
		topLocationsStatus,
		userLocation,
	];
}
