import { capitalizeFirstLetter } from '../utils';
import CareIcon from '../assets/images/care.svg';
import PlayIcon from '../assets/images/play.svg';
import StyleIcon from '../assets/images/style.svg';

const SHOW_SERVICES = {
    care: CareIcon,
    play: PlayIcon,
    style: StyleIcon
}

const ServiceIndicator = (props) => {

    const { appointmentType } = props;

    return (<div className="careare">
        <div className="care_inner">
            <span className="plus">
                <img src={SHOW_SERVICES[appointmentType]} />

            </span>
            <span>{capitalizeFirstLetter(appointmentType)}</span>
        </div>
    </div>
    )
}

export default ServiceIndicator;