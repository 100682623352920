import { Children, cloneElement } from "react";
import authAxios from "./authAxios";
import axios from "axios";
import { AUTH_COOKIE_EXP, returnEnvDomain } from "./const";
import ROUTES from "./routes";

/**
 * @typedef {Object} CalendarDate
 * @property {number} day
 * @property {number} month
 * @property {number} year
 */

/**
 * Attaches credentials to fetch without requiring adding options
 *
 * @param {string} endpoint
 * @param {Object} options - options that fetch can use
 * @returns {Promise}
 */
export const fetchWithCredentials = (endpoint, options = {}) => {
  return fetch(endpoint, {
    ...options,
    credentials: "include",
    headers: new Headers({
      Authorization: localStorage.getItem("authToken"),
    }),
  });
};

export const findSlot = (
  children = [],
  props = {},
  componentType = "Unknown"
) => {
  const childrenArray = Children.toArray(children);
  //console.log(childrenArray);
  const cmp =
    childrenArray.find((child) => child.type === componentType) || null;
  if (cmp) return cloneElement(cmp, props);
  return cmp;
};

export const saveToSession = (key, value) => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (err) {
    console.error(err);
  }
};

export const retrieveFromSession = (key) => {
  try {
    const value = sessionStorage.getItem(key);
    if (!value) return null;
    return JSON.parse(value);
  } catch (err) {
    return null;
  }
};

/**
 *
 * Returns today through the next 6 days
 *
 * @returns {{ from: CalendarDate, to: CalendarDate }}
 */
export const getDefaultAppointmentRange = () => {
  const today = new Date();
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 6);
  return {
    from: {
      day: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear(),
    },
    to: {
      day: futureDate.getDate(),
      month: futureDate.getMonth() + 1,
      year: futureDate.getFullYear(),
    },
  };
};

export const getGroomingAppointmentRange = () => {
  const today = new Date();
  const futureDate = new Date();
  futureDate.setDate(futureDate.getDate() + 30);
  return {
    from: {
      day: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear(),
    },
    to: {
      day: futureDate.getDate(),
      month: futureDate.getMonth() + 1,
      year: futureDate.getFullYear(),
    },
  };
};

// Parameters could be in multiple formats depending on
// how the parameters are applied (before the hash or after the hash)
const paramStr =
  window.location.hash.indexOf("?") > 0
    ? `?${window.location.hash.split("?")[1]}`
    : window.location.search;
export const paramsOnLoad = new URLSearchParams(paramStr);

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const storeUserAuthToken = (
  token,
  day = AUTH_COOKIE_EXP,
  loginWith = null
) => {
  authAxios.defaults.headers.Authorization = token;
  setCookie("authToken", token, day, true);
  if (loginWith)
    localStorage.setItem("loginWith", loginWith);
  // setCookie("loginWith", loginWith, day);
};

export const removeUserAuthToken = () => {
  deleteCookie("authToken");
  deleteCookie("loginWith");
  localStorage.removeItem("EXPIRES_TIME");
  localStorage.removeItem("loginWith");
};

export function setCookie(name, value, days, isStore = false) {
  // let minutes = 10;

  var expires = "";
  if (days) {
    var date = new Date();

    // date.setTime(date.getTime() + (minutes * 60 * 1000));

    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

    let expiresDate = date.toUTCString();

    expires = "; expires=" + expiresDate;
    if (isStore)
      localStorage.setItem("EXPIRES_TIME", JSON.stringify(expiresDate));
  }
  document.cookie =
    name + "=" + (value || "") + expires + "; path=/" + ";secure";
}

export function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");

  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function deleteCookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function getDateArray(start, end) {
  const arr = new Array();
  const tt = new Date(start);
  const dt = new Date(tt.getTime() + Math.abs(tt.getTimezoneOffset() * 60000));
  const edt = new Date(
    end.getTime() + Math.abs(end.getTimezoneOffset() * 60000)
  );
  while (dt <= edt) {
    arr.push(new Date(dt));
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
}
export async function sendAbandonBookingData(body) {
  try {
    const url = `${returnEnvDomain()}/api/abandonbooking/data-store`;
    const response = await axios.post(url, body);
    return response;
  } catch (error) {
    console.log("error", error);
  }
}

export function abandonBookingPayload(state, page, isAppt = false) {
  const apptType = state.careTypes?.find(
    (type) => type.value === state.appointmentTypeId
  );
  const payload = {
    userType: state.newUser ? "new" : "return",
    userMessageType: state.loginWith,
    isAppointmentAvailable: Object.keys(state.selectedAppointmentSlot)?.length
      ? true
      : false,
    appointmentTypes: state.appointmentTypes,
    careTypes: state.careTypes,
    searchAddress: state.searchAddress,
    appointmentRange: state.appointmentRange,
    selectedAppointmentSlot: state.selectedAppointmentSlot,
    selectedLocation: state.selectedLocation,
    selectedPatients: state.selectedPatients,
    serviceOption: state.serviceOption,
    serviceDetail: {
      serviceId: state.appointmentTypeId,
      serviceName: state.appointmentType,
      appointmentType:
        state.appointmentType === "care"
          ? apptType?.name
          : state.appointmentType,
      moreNote: state.appointmentReason,
      otherNote: state.appointmentReasonOtherText,
    },
    patients: state.patients,
    client: {
      cardconnectExpiry: state.cardconnectExpiry,
      cardconnectToken: state.cardconnectToken,
      email: state.email,
      externalCommPreferences: state.externalCommPreferences,
      familyName: state.familyName,
      givenName: state.givenName,
      id: state.id,
      primaryLocationId: state.primaryLocationId,
      phone: state.phone,
      appointment_count: state.appointment_count,
      noShowAppointment_count: state.noShowAppointment_count,
    },
  };

  switch (page) {
    case "selectPet":
      return { ...payload, pageName: "selectPet" };
    case "selectAppt":
      return {
        ...payload,
        pageName: "selectAppt",
        isAppointmentAvailable: isAppt,
      };
    case "contactInfo":
      return { ...payload, pageName: "contactInfo" };
    case "creditCardInfo":
      return { ...payload, pageName: "creditCardInfo" };
    case "reviewAppt":
      return { ...payload, pageName: "reviewAppt" };
    case "selectService":
      return { ...payload, pageName: "selectService" };
  }
}

export const pageLinkObject = {
  selectPet: ROUTES.petSelect,
  selectAppt: ROUTES.selectAppointment,
  contactInfo: ROUTES.ownerInfo,
  creditCardInfo: ROUTES.CreditCardinfo,
  reviewAppt: ROUTES.finalize,
  selectService: ROUTES.selectServices,
};
