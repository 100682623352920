import { useEffect } from "react";

/**
 * Pass in a title
 * @param {string} title
 * @returns {boolean} - lets you know if a push happened
 */
export default function useVirtualPageView(title) {
	let pushed = false;

	useEffect(() => {
		if (window.dataLayer) {
			window.dataLayer.push({ event: "VirtualPageView", title: title });
			pushed = true;
		} else {
			console.warn(
				"[GTM]: GTM is not installed. The following object is what would have been sent."
			);
			console.table({ event: "VirtualPageView", title: title });
		}
	}, []);

	return pushed;
}