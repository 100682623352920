import routes from "../routes";
import { errorObject } from "./utils";
import isEmail from "validator/lib/isEmail";

export const validateClient = (client) => {
	const errors = {};

	if (!client.givenName) errors.givenName = true;
	if (!client.familyName) errors.familyName = true;
	if (!isEmail(client.email)) errors.email = true;
	if (!client.phone || client.phone.length !== 10) errors.phone = true;

	// if (!client.address.line1 || !client.address.city || !client.address.state) {
	// 	errors.address = true;
	// }
	return errorObject(errors, routes.ownerInfo);
};
