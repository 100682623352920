import { useEffect, useRef } from "react";
import { useGlobalStateContext } from "../contexts/GlobalContext/index.jsx";
import { usePaginationContext } from "../contexts/PaginationContext/index.jsx";

/**
 * @typedef {Object} ValidationParameters
 * @property {function|null} checkPrerequisiteState - handles checking previous pages' data
 * @property {function} handleLinkUpdates - handles updating where pagination points to
 * @property {function} handlePageValidation - handles validating the current page
 * @property {any} watch - state we're watching
 * @property {boolean} hide - hide the pagination at the bottom, for use on pages where next/back doesn't apply
 */

/**
 * useValidation is core to the validation and progress of a user
 * through the booking tool.
 *
 * @param {ValidationParameters} parameters
 */
export default function useValidation({
	checkPrerequisiteState = null,
	handleLinkUpdates = () => null,
	handlePageValidation = () => false,
	watch = null,
	hide = false,
}) {
	//console.log("useValidation", checkPrerequisiteState);
	const firstRun = useRef(true);
	const { updatePagination, setValidator, setHidePagination } =
		usePaginationContext();

	const globalState = useGlobalStateContext();
	//console.log(`[VALIDATION]: Do we need to hide the pagination?`, hide);
	if (firstRun.current) {
		firstRun.current = false;
		if (checkPrerequisiteState) {
			//console.log("checking first time", globalState.state);
			checkPrerequisiteState(globalState.state);
		}

		// Make sure we recheck, if applicable. We really don't
		// want to ever give someone a chance to mess around
		// and make it further than they should with bad data.
		setValidator(
			!checkPrerequisiteState
				? handlePageValidation
				: (state) => {
					checkPrerequisiteState(state);
					return handlePageValidation(state);
				}
		);
		setHidePagination(hide);
	}

	// Very few times we'll have watch = null.
	// We're using this as a way to keep the links reactive
	// but without doing all of this inside of each page
	//if (watch) {
	useEffect(() => {
		if (watch) {
			//console.log("");
			const object = handleLinkUpdates(watch);
			//console.log("pagination object", object);
			if (object) updatePagination(object);
		}
	}, [watch]);
	//}
}
