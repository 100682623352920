import routes from "../routes";
import { errorObject } from "./utils";
import { validatePet, validatePetSelect } from "./pets";
import { validateClient } from "./client";

const validateAppointmentType = (state) => {
	// TODO: add check for appointmentTypeId
	if (!state.appointmentType)
		return { error: true, path: `${routes.type}?errors=type` };
	if (!state.appointmentType && state.isTodaysAppointment && !state.selectedAppointmentSlot)
		return { error: true, path: '/today-appointment?isToday=true' };
	return { error: false };
};

const validateAppointmentSlot = (selectedAppointmentSlot = {}) => {
	const errors = {};
	console.log("selected appointment slot", selectedAppointmentSlot);
	if (Object.keys(selectedAppointmentSlot).length === 0)
		errors.selectedAppointmentSlot = true;
	return errorObject(errors);
};

const validateAppointmentServices = ({ serviceOption }) => {
	const errors = {};
	if (!serviceOption) errors.serviceOption = true;

	return errorObject(errors, routes.selectServices);
};

const validateTerms = ({ terms }) => {
	const errors = {};
	if (!terms)
		errors.terms = true;

	return errors.terms = true;

	return errorObject(errors, routes.finalize);
};
export {
	validateAppointmentSlot,
	validateAppointmentType,
	validateAppointmentServices,
	validatePet,
	validatePetSelect,
	validateClient,
	validateTerms,
};
