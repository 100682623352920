import { useEffect } from "react";
//import { useHistory } from "react-router";
import { useHistory } from "react-router-dom";
import { usePaginationContext } from "../../contexts/PaginationContext/index.jsx";
import useVirtualPageView from "../../hooks/gtmHooks";
export default function Page({
	children,
	title = "Booking Tool",
	onKeyPress = null,
}) {
	useVirtualPageView(title);
	const {
		paginationState,
		checkValidation,
		hidePagination,
	} = usePaginationContext();
	const { replace } = useHistory();

	const history = useHistory();

	useEffect(() => {
		document.title = `${title} | Heart + Paw`;
	}, []);
	const attemptSubmit = () => {
		const passed = checkValidation();
		const nextUrl = paginationState?.next?.url;
		const disabled = paginationState?.next?.enabled;
		if (passed) {
			//replace(nextUrl);
			history.push(nextUrl);
			//console.log("passed===>>>1000", passed)
		}

		//console.log("passed===>>>", disabled)

	};
	return (
		<div
			id="hap-booking-form"
			className={`pt-8 ${hidePagination ? "" : "pb-32"} w-full custom_page`}
			role="form"
			aria-label={`${title} page of the multi-step Heart and Paw booking tool`}
			onKeyPress={(e) => {
				if (e.key === "Enter") {
					attemptSubmit();
				}
			}}
		>
			<h1 className="sr-only">{title}</h1>
			{children}
		</div>
	);
}
