import { Children, useState } from "react";
import Calendar from "./Calendar.jsx";
import ErrorMessage from "../../generics/Error.jsx";
import GreenLabel from "../../generics/GreenLabel.jsx";

import { ReactComponent as CalendarIcon } from '../../../assets/icons/date-range.svg';

const months = [
	"January",
	"Feburary",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const prettyPrintDate = (
	{ day: day1, month: month1, year: year1 },
	{ day: day2, month: month2, year: year2 }
) => {
	if (day1 === day2 && month2 === month1 && year1 === year2) {
		return `${months[month1 - 1]} ${day1}`;
	}
	return `${months[month1 - 1]} ${day1} - ${months[month2 - 1]} ${day2}`;
};
function CalendarInput({
	children,
	calendarValue,
	onClose,
	text = "Select a date range",
	error = false,
	className = null,
}) {
	const [isOpen, setIsOpen] = useState(false);
	const kids = Children.toArray(children);
	//console.log("calendar kids", kids);
	const LabelSlot = () =>
		kids.find((child) => child.type === GreenLabel) || null;
	const ErrorSlot = () =>
		kids.find((child) => child.type === ErrorMessage) || null;
	return (
		<div className={className}>
			<LabelSlot id="calendar"></LabelSlot>
			<button
				id="calendar"
				className="cus_calender bg-white w-full md:max-w-xl rounded-lg border-2 border-solid  px-3 py-2 border-gray-400 text-left flex items-center justify-between"
				type="button"
				onClick={() => setIsOpen(!isOpen)}
			>
				{calendarValue.from && calendarValue.to
					? `${prettyPrintDate(calendarValue.from, calendarValue.to)}`
					: "Select a date range"}
				<div className="w-4 text-primary-500">
					<CalendarIcon />
				</div>
				<span className="sr-only">Click or tap to change range.</span>
			</button>
			{error && <ErrorSlot />}
			{isOpen && (
				<Calendar
					isOpen={isOpen}
					closeModal={() => setIsOpen(false)}
					onClose={(event) => onClose(event)}
					selectedRange={calendarValue}
				/>
			)}
		</div>
	);
}

CalendarInput.ErrorMessage = ErrorMessage;
CalendarInput.Label = GreenLabel;
export default CalendarInput;
