import Page from "../components/containers/Page.jsx";
import { Link } from "react-router-dom";
import useValidation from "../hooks/useValidation";
import routes from "../routes";

export default function Page404() {
	useValidation({
		hide: true,
	});
	return (
		<Page title="404">
			<div className="text-center">
				<h2 className="text-5xl text-red-700 font-bold">404.</h2>
				<p>You've reached a page that does not exist.</p>
				<p className="font-bold pt-2">
					<Link to={routes.type}>
						Let's begin booking your pet's next appointment.
					</Link>
				</p>
			</div>
		</Page>
	);
}
