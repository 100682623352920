import classnames from "classnames";

export default function Label({
	id,
	children,
	// font-bold
	className = "block mb-2",
	error,
	labelOverride = false,
	hidden = false,
	onClick = null,
	...rest
}) {
	let labelId = labelOverride ? id : `${id}_label`;
	//console.log(14, "green label", labelOverride, labelId);
	return (
		<label
			id={labelId}
			className={classnames(
				// error ? "text-red-700" : "text-primary-500",
				"text-primary-500",
				hidden ? "sr-only" : "",
				className
			)}
			onClick={onClick}
			for={id}
			{...rest}
		>
			{children}
		</label>
	);
}
