import { createContext, useContext, useMemo, useState, useRef } from "react";
import { pushToGTM } from "../../gtm.js";
import { useGlobalStateContext } from "../GlobalContext/index.jsx";

/**
 * This handles all of the pagination and validation of the
 * various steps as a user progresses through the booking tool
 * process.
 */
const PaginationContext = createContext();

const usePaginationContext = () => {
	const context = useContext(PaginationContext);
	if (!context)
		throw new Error(
			"[Pagination Context]: Not used within a PaginationContextProvider."
		);

	const { state } = useGlobalStateContext();
	const [
		paginationState,
		setPaginationState,
		validator,
		hidePagination,
		setHidePagination,
	] = context;

	return {
		paginationState,
		setPaginationState,
		validator,
		hidePagination,
		setValidator(fn) {
			// if a validator isn't passed in, then default to false
			validator.current =
				typeof fn === "function"
					? fn
					: () => {
							console.warn("No validator passed in");
							return false;
					  };
		},
		updatePagination(obj) {
			setPaginationState(Object.assign({}, paginationState, obj));
		},
		checkValidation() {
			const passed = validator.current(state);
			if (passed.error) {
				console.warn(`[VALIDATION]: Validation for this step did not pass.`);
				console.error(passed);
				pushToGTM({ event: "PageValidationFailure", errors: passed });
			}
			window.scrollTo(0, 0);
			return !passed.error;
		},
		setHidePagination(value) {
			setHidePagination(value);
		},
	};
};

const PaginationContextProvider = ({ children }) => {
	const validator = useRef(() => false);
	const [hidePagination, setHidePagination] = useState(false);
	const [paginationState, setPaginationState] = useState({
		back: { url: null, text: "Back", enabled: false },
		next: { url: null, text: "Next", enabled: false },
		completedPercent: 0,
	});
	const value = useMemo(
		() => [
			paginationState,
			setPaginationState,
			validator,
			hidePagination,
			setHidePagination,
		],
		[paginationState, validator.current, hidePagination]
	);

	return (
		<PaginationContext.Provider value={value}>
			{children}
		</PaginationContext.Provider>
	);
};

export { usePaginationContext, PaginationContextProvider };
