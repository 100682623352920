import { useEffect, useState, Fragment } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Dialog, Transition } from "@headlessui/react";
import CloseIcon from "../assets/images/Close.svg";

const IdleDetectionAlert = (props) => {
  const { messageAlert } = props;
  const [state, setState] = useState("Active");
  const [isOpen, setIsOpen] = useState(false);
  const onIdle = () => {
    setState("Idle");
  };

  const onActive = () => {
    setState("Active");
  };

  useEffect(() => {
    // console.log("state->>", state);
    if (state === "Idle") {
      setIsOpen(true);
    }
  }, [state]);

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    timeout: 120_000,
    throttle: 600,
  });

  const closeHandler = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                style={{ opacity: 0.3 }}
                className="fixed inset-0 bg-black pointer-events-none"
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="VerifyOtp inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="model_title flex justify-between items-center text-lg font-medium leading-6 text-gray-900 mb-4"
                >
                  <span style={{ cursor: "pointer" }} onClick={closeHandler}>
                    <img src={CloseIcon} />
                  </span>
                </Dialog.Title>
                <div className="verify_otp_contant" onClick={closeHandler}>
                  {messageAlert}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default IdleDetectionAlert;
