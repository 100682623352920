export default {
  //appointment
  SET_TYPE_ID: "APPT_SET_TYPE_ID",
  SET_API_DATA: "APPT_SET_APPOINTMENT_TYPES_API_DATA",
  SET_CLIENT_AUTH_DATA: "SET_CLIENT_AUTH_DATA",

  // patient info
  MANAGE_PET: "MANAGE_PET",
  DELETE_PET: "DELETE_PET",
  SELECT_PET: "SELECT_PET",
  DESELECT_PET: "DESELECT_PET",
  PET_API_STATUS: "idle",

  SET_SPAYED_NEUTERED: "SET_SPAYED_NEUTERED",
  SET_GENDER: "SET_GENDER",

  //locations
  SET_LOCATION: "SET_LOCATION",

  SET_BREED: "SET_BREED",
  SET_SPECIES: "SET_SPECIES",

  SET_APPOINTMENT: "SET_APPOINTMENT",
  SET_APPOINTMENT_RANGE: "SET_APPOINTMENT_RANGE",
  SET_MAIN_SERVICE: "SET_MAIN_SERVICE",
  SET_ADD_ONS: "SET_ADD_ONS",
  SET_CARE_REASON: "SET_CARE_REASON",
  SET_TERMS: "SET_TERMS",
  SET_APPOINTMENT_REASON: "SET_APPOINTMENT_REASON",
  // SET_PREFERRED_PROVIDERS: "SET_PREFERRED_PROVIDERS",
  SET_IS_TODAYS_APPOINTMENT: "SET_IS_TODAYS_APPOINTMENT",
  SET_TODAYS_APPOINTMENT: "SET_TODAYS_APPOINTMENT",

  SET_EMAIL: "SET_EMAIL",
  SET_GIVEN_NAME: "SET_GIVEN_NAME",
  SET_FAMILY_NAME: "SET_FAMILY_NAME",
  SET_PHONE_NUMBER: "SET_PHONE_NUMBER",
  SET_CREDITCARD_INFO: "SET_CREDITCARD_INFO",
  SET_MESSAGING: "SET_MESSAGING",
  SET_ADDRESS: "SET_ADDRESS",
  SET_GRAPEVINE: "SET_GRAPEVINE",
  RESET: "RESET",
  SET_USER_AUTH: "SET_USER_AUTH",
  SET_NEW_USER: "SET_NEW_USER",
  SET_AUTH_TOKEN: "SET_AUTH_TOKEN",
  SET_APPOINTMENT_REASON_OTHER: "SET_APPOINTMENT_REASON_OTHER",
  SET_CENTER_LOCATION: "SET_CENTER_LOCATION",
  SET_LOGIN_WITH: "SET_LOGIN_WITH",
  SET_GLOBAL_STATE: "SET_GLOBAL_STATE",
};
