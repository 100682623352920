import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

import CloseIcon from "../../assets/images/Close.svg";

import Erroralert from "../../assets/images/ErrorAlert.svg";
const ErrorAlert = (props) => {
  const { isOpen, apiErrorCode, onCloseHandler } = props;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {
          onCloseHandler();
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              style={{ opacity: 0.3 }}
              className="fixed inset-0 bg-black pointer-events-none"
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="VerifyOtp error-alert inline-block  max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="model_title flex justify-between items-center text-lg font-medium leading-6 text-gray-900 mb-4"
              >
                <span
                  onClick={() => {
                    onCloseHandler();
                  }}
                >
                  <img src={CloseIcon} style={{ cursor: "pointer" }} />
                </span>
              </Dialog.Title>

              <div className="verify_otp_contant">
                <div className="error-image">
                  <img src={Erroralert} />
                </div>

                <div className="error-message">{apiErrorCode}</div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ErrorAlert;
