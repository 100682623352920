import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import { useGlobalStateContext } from "../contexts/GlobalContext/index.jsx";
import { usePaginationContext } from "../contexts/PaginationContext/index.jsx";
export default function Pagination(props) {
	const { state } = useGlobalStateContext();
	const {
		paginationState,
		checkValidation,
		hidePagination,
	} = usePaginationContext();

	const saveToSession = () => {
		try {
			sessionStorage.setItem("hap-booking", JSON.stringify(state));
		} catch (err) {
			console.warn(err);
		}
	};

	return (
		<div className="pagination-container">
			{!hidePagination && (
				<div className="h-16 fixed flex space-x-4 justify-center items-center left-0 p-4 bottom-0 border-t-2 border-gray-200 border-solid bg-white w-full">
					{paginationState.back.enabled && (
						<Link
							className="is-style-white-button outline-offset-2"
							to={paginationState.back.url}
						>
							<span className="block-style py-2 px-4" style={{color: "#017E5F", fontWeight: "600"}}>
								{paginationState.back.text || "Back"}
								<span className="sr-only"> a Page</span>
							</span>
						</Link>
					)}
					<Link

						className={classnames(
							paginationState.next.enabled
								? "opacity-100"
								: "cursor-not-allowed opacity-50",
							"is-style-green-button outline-offset-2"
						)}

						to={paginationState.next.enabled ? paginationState.next.url : '#'}
					>
						<span className="block-style py-2 px-4">
							{paginationState.next.text || "Next"}
							<span className="sr-only"> Page</span>
						</span>
					</Link>
				</div>
			)}
		</div>
	);
}
