/**
 * These are all of the routes that the booking tool
 * responds to; these are used throughout to guide pagination,
 * and we reference it this way for easier readable in the code
 * and allows us to change the URLs in one spot without worry.
 */
const ROUTES = {
  type: "/",
  signIn: "/sign-in",
  authSync: "/sign-in/sync",
  petSelect: "/select-pets",
  selectAppointment: "/select-appointment",
  selectServices: "/select-services",
  ownerInfo: "/owner-info",
  finalize: "/review",
  CreditCardinfo: "/credit-card-info",
  bookAppointment: "/book-appointment",
  register: "/register",
  redirectAbandonBooking: "/redirect-abandon-booking",
  referAFriend: "/refer-link",
  todaysAppointment: "/today-appointment",
};

export default ROUTES;
