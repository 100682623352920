export default function Loader({ children, color = "" }) {
	return (
		<div
			role="progressbar"
			aria-busy="true"
			className={`mx-auto h-16 w-16 relative booking-loader ${color}`}
		>
			<div className="sr-only">
				{children ? children : "Loading content..."}
			</div>
		</div>
	);
}
