import React from "react";
import { useEffect } from "react";

import { REACT_APP_GOOGLE_CLIENT_ID } from "../../const";

const GoogleBtn = (props) => {
  function handleCredentialResponse(response) {
    // console.log("this is the token", response.credential);
    props.onbtnclick(response.credential);
  }

  useEffect(() => {
    /*global google*/
    google.accounts.id.initialize({
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse,
    });

    google.accounts.id.renderButton(document.getElementById("google_button"), {
      text: "continue_with",
      theme: "outline",
      type: "standard",
      size: "large",
      context: "signin",
      logo_alignment: "left",
      className: "hello",
    });

    // google.accounts.id.prompt();
  }, []);

  return (
    <div className="google-parent">
      <button
        id="google_button"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      />
    </div>
  );
};

export default GoogleBtn;
