/**
 * Recursively deletes specified keys; this is used
 * in the removal of sensitive data or just data that we'd 
 * rather not bloat GTM's data layer with
 * 
 * @param {Object} payload - gtm data
 * @param {String[]} keysToDelete
 * @returns Object
 */
function deleteKeys(payload, keysToDelete) {
	const payloadKeys = Object.keys(payload);
	return payloadKeys.reduce((acc, curr) => {
		//console.log(curr, payload[curr], typeof payload[curr]);
		if (typeof payload[curr] === "object" && payload[curr] !== null) {
			acc[curr] = deleteKeys(payload[curr], keysToDelete);
		} else {
			//console.log(curr);
			if (keysToDelete.indexOf(curr) < 0) {
				acc[curr] = payload[curr];
			}
		}
		return acc;
	}, {});
}

/**
 * Acts like a middleware in the reducer to send along 
 * (almost) all of the data every time the reducer is updated
 * 
 * This is done to allow analytics & others working inside
 * of GTM to have access to as much data as possible without
 * having to wait for code changes to retrieve more.
 * 
 * `DL - Payload` variable in GTM can be used to access
 * this data at any given point
 * 
 * @param {string} type 
 * @param {object} payload 
 * @param {string[]?} extraKeysToRemove	- pass in extra string keys to 
 * 																				remove any additional data from payload
 */
export function reducerToGTM(type, payload, extraKeysToRemove = []) {
	//console.log("gtm", type, payload, typeof payload);

	// Deletes PII + any additional data requested
	const keysToDelete = [
		"email",
		"familyName",
		"givenName",
		"phone",
		"address",
		...extraKeysToRemove,
	];
	const gtmPayload = deleteKeys(payload, keysToDelete);

	const analyticsData = {
		event: "Reducer",
		category: type,
		payload: gtmPayload,
	};
	pushToGTM(analyticsData);
}

/**
 * Handles pushing some kind of JS object, most likely associated to
 * a custom event, to GTM. It usually has some other data to go
 * along with it, but nothing is strictly required.
 * @param {{ 
 * 		event?: string, 
 * 		category?: string, 
 * 		action?: string, 
 * 		label?: string
 * 		payload? Object
 * 		[string]?: string | Object
 * 	}} analyticsData 
 */
export function pushToGTM(analyticsData) {
	if (window.dataLayer) {
		window.dataLayer.push(analyticsData);
	} else {
		console.warn("[ANALYTICS]: No GTM found. Would have passed the following");
		//console.log(analyticsData);
	}
}
