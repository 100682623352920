import React, { useState, Fragment } from "react";
import { Calendar } from "react-modern-calendar-datepicker";
import { Dialog, Transition } from "@headlessui/react";

const todayDate = new Date();
const today = {
  month: todayDate.getMonth() + 1,
  day: todayDate.getDate(),
  year: todayDate.getFullYear(),
};

export default function DatePicker({
  isOpen = false,
  closeModal = () => {
    console.warn("[CAL]: Cannot close modal");
  },
  onClose = () => console.warn("[CAL]: The Calendar is not bound"),
  selectedRange = { from: null, to: null },
}) {
  const [errorState, setErrorState] = useState(false);
  const [selectedDayRange, setSelectedDayRange] = useState(selectedRange);

  const onChange = (e) => {
    setErrorState(false);
    setSelectedDayRange(e);
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto z-front"
          onClose={() => {
            closeModal();
          }}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                style={{ opacity: 0.3 }}
                className="fixed inset-0 bg-black"
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block my-8 overflow-hidden text-left align-middle transition-all transform shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="sr-only flex justify-between items-center text-lg font-medium leading-6 text-gray-900 mb-4"
                >
                  Select a start and end date
                </Dialog.Title>
                <div className="mt-2 grid gap-4">
                  <Calendar
                    colorPrimary="#017E5F"
                    colorPrimaryLight="#D7EFE0"
                    value={selectedDayRange}
                    onChange={onChange}
                    minimumDate={today}
                    selectorStartingYear={new Date().getFullYear()}
                    selectorEndingYear={new Date().getFullYear() + 1}
                    renderFooter={() => {
                      return (
                        <div className="calender_container text-center">
                          <p className="para_calender">
                            Please select a date range to search for available
                            appointments. Click twice to search for a single
                            day.
                          </p>
                          <div className="mt-4 flex justify-center gap-2 calender_btn">
                            {errorState && (
                              <strong>please pick a real date.</strong>
                            )}
                            <button
                              style={{
                                backgroundColor: "gray",
                                padding: "10px 20px 10px 20px",
                              }}
                              className="bg-primary-500 text-white rounded-lg p-2 md:px-6"
                              onClick={() => {
                                closeModal();
                              }}
                              type="button"
                            >
                              Cancel
                            </button>
                            <button
                              className="bg-primary-500 text-white rounded-lg p-2 md:px-6"
                              style={{ padding: "10px 20px 10px 20px" }}
                              onClick={() => {
                                if (
                                  selectedDayRange.from &&
                                  selectedDayRange.to
                                ) {
                                  onClose(selectedDayRange);
                                }
                                closeModal();
                              }}
                              type="button"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
